var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal fade\" id=\"importStatsModal\" role=\"dialog\" tabindex=\"-1\" aria-labelledby=\"importStatsModalLabel\">\n   <div class=\"modal-dialog modal-dialog-centered\" role=\"document\">\n      <div class=\"modal-content\">\n         <div class=\"modal-header\">\n            <h5 class=\"modal-title\">Data Import Statistics</h5>\n         </div>\n         <div class=\"modal-body\">\n            <div class=\"row\">\n               <div class=\"col-md-12\">\n                  <ul>\n                    <li> Number of imported nodes:  "
    + alias4(((helper = (helper = lookupProperty(helpers,"nb_nodes") || (depth0 != null ? lookupProperty(depth0,"nb_nodes") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"nb_nodes","hash":{},"data":data,"loc":{"start":{"line":11,"column":52},"end":{"line":11,"column":64}}}) : helper)))
    + " </li>\n                    <li> Number of imported links:  "
    + alias4(((helper = (helper = lookupProperty(helpers,"nb_links") || (depth0 != null ? lookupProperty(depth0,"nb_links") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"nb_links","hash":{},"data":data,"loc":{"start":{"line":12,"column":52},"end":{"line":12,"column":64}}}) : helper)))
    + " </li>\n                    <li> Number of nodes removed (no links):  "
    + alias4(((helper = (helper = lookupProperty(helpers,"nb_removed_nodes") || (depth0 != null ? lookupProperty(depth0,"nb_removed_nodes") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"nb_removed_nodes","hash":{},"data":data,"loc":{"start":{"line":13,"column":62},"end":{"line":13,"column":82}}}) : helper)))
    + " </li>\n                    <li> Number of links removed (missing nodes):  "
    + alias4(((helper = (helper = lookupProperty(helpers,"nb_removed_links") || (depth0 != null ? lookupProperty(depth0,"nb_removed_links") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"nb_removed_links","hash":{},"data":data,"loc":{"start":{"line":14,"column":67},"end":{"line":14,"column":87}}}) : helper)))
    + " </li>\n                    <li> Number of links after aggregation: "
    + alias4(((helper = (helper = lookupProperty(helpers,"nb_aggregated_links") || (depth0 != null ? lookupProperty(depth0,"nb_aggregated_links") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"nb_aggregated_links","hash":{},"data":data,"loc":{"start":{"line":15,"column":60},"end":{"line":15,"column":83}}}) : helper)))
    + "</li>\n                  </ul>\n               </div>\n             </div>\n\n           </div>\n          <hr>\n         <div class =\"modal-footer\">\n            <button class=\"btn btn-dark btn-block justify-content-center mt-2\" type=\"button\" id=\"Launch\" data-dismiss=\"modal\">Ok</button>\n         </div>\n      </div>\n   </div>\n</div>\n";
},"useData":true});